#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 357px 748px #fff, 1626px 800px #fff, 357px 1286px #fff, 1027px 721px #fff, 1754px 224px #fff, 1560px 769px #fff, 881px 1605px #fff, 650px 1228px #fff, 1992px 1304px #fff, 1862px 1101px #fff, 1613px 738px #fff, 650px 436px #fff, 1461px 1062px #fff, 741px 1525px #fff, 1321px 759px #fff, 1002px 346px #fff, 1360px 765px #fff, 1858px 461px #fff, 543px 15px #fff, 1081px 1747px #fff, 17px 1654px #fff, 1988px 319px #fff, 1695px 1911px #fff, 606px 1802px #fff, 1883px 1127px #fff, 1627px 1841px #fff, 98px 922px #fff, 1090px 688px #fff, 568px 701px #fff, 767px 469px #fff, 1668px 1597px #fff, 816px 1730px #fff, 469px 1848px #fff, 310px 33px #fff, 1390px 593px #fff, 143px 582px #fff, 1769px 1251px #fff, 1327px 1263px #fff, 364px 999px #fff, 1531px 1651px #fff, 1967px 1862px #fff, 416px 1241px #fff, 601px 970px #fff, 1759px 68px #fff, 1093px 66px #fff, 1963px 519px #fff, 1396px 1032px #fff, 1689px 319px #fff, 1696px 1116px #fff, 165px 122px #fff, 238px 1673px #fff, 1165px 510px #fff,
    260px 657px #fff, 131px 207px #fff, 1976px 756px #fff, 98px 1025px #fff, 1971px 677px #fff, 517px 1771px #fff, 313px 1267px #fff, 1917px 209px #fff, 1044px 1181px #fff, 1663px 325px #fff, 143px 856px #fff, 1449px 792px #fff, 1153px 262px #fff, 12px 1697px #fff, 1937px 824px #fff, 525px 1189px #fff, 1871px 1670px #fff, 1440px 1191px #fff, 178px 1273px #fff, 1898px 1788px #fff, 722px 927px #fff, 305px 1404px #fff, 1737px 92px #fff, 845px 702px #fff, 1356px 221px #fff, 1974px 385px #fff, 621px 1684px #fff, 438px 1676px #fff, 355px 772px #fff, 535px 480px #fff, 511px 489px #fff, 337px 545px #fff, 1039px 1527px #fff, 1860px 912px #fff, 168px 1959px #fff, 1855px 1667px #fff, 1474px 1914px #fff, 1553px 1038px #fff, 514px 1478px #fff, 1900px 1036px #fff, 330px 1639px #fff, 1944px 1470px #fff, 1596px 583px #fff, 965px 1492px #fff, 746px 233px #fff, 761px 58px #fff, 1690px 1381px #fff, 1736px 1733px #fff, 1627px 1043px #fff, 365px 782px #fff, 286px 1486px #fff, 1251px 1356px #fff,
    1297px 696px #fff, 1702px 1686px #fff, 391px 1506px #fff, 1536px 1392px #fff, 90px 678px #fff, 51px 646px #fff, 958px 1808px #fff, 1078px 526px #fff, 698px 465px #fff, 590px 1603px #fff, 190px 1496px #fff, 1600px 1885px #fff, 656px 844px #fff, 1556px 155px #fff, 356px 5px #fff, 579px 663px #fff, 728px 367px #fff, 1054px 657px #fff, 677px 1992px #fff, 1498px 395px #fff, 1744px 324px #fff, 1677px 1538px #fff, 969px 1409px #fff, 1044px 831px #fff, 124px 915px #fff, 762px 1985px #fff, 1894px 1440px #fff, 6px 1755px #fff, 215px 1780px #fff, 1304px 1290px #fff, 673px 396px #fff, 583px 1086px #fff, 1416px 1915px #fff, 1295px 327px #fff, 1437px 493px #fff, 1775px 390px #fff, 1889px 744px #fff, 592px 758px #fff, 19px 732px #fff, 268px 1537px #fff, 1941px 1769px #fff, 1565px 1209px #fff, 469px 46px #fff, 416px 404px #fff, 416px 1603px #fff, 1064px 136px #fff, 1252px 798px #fff, 14px 1100px #fff, 758px 944px #fff, 1827px 1841px #fff, 286px 176px #fff, 451px 1818px #fff, 1398px 1483px #fff,
    1002px 1034px #fff, 709px 877px #fff, 802px 680px #fff, 488px 1675px #fff, 1576px 413px #fff, 323px 1157px #fff, 287px 920px #fff, 709px 1324px #fff, 1745px 1613px #fff, 943px 1687px #fff, 329px 1208px #fff, 401px 1085px #fff, 577px 1117px #fff, 1736px 354px #fff, 719px 557px #fff, 1292px 1003px #fff, 323px 1841px #fff, 1505px 1151px #fff, 1360px 1px #fff, 520px 1271px #fff, 1586px 916px #fff, 707px 582px #fff, 1307px 1501px #fff, 1124px 914px #fff, 737px 41px #fff, 1497px 1397px #fff, 670px 1016px #fff, 687px 1882px #fff, 107px 950px #fff, 1325px 7px #fff, 998px 327px #fff, 827px 1698px #fff, 251px 933px #fff, 683px 1936px #fff, 1992px 905px #fff, 1024px 399px #fff, 433px 1211px #fff, 830px 1919px #fff, 1980px 946px #fff, 1842px 770px #fff, 314px 1899px #fff, 573px 1027px #fff, 1633px 606px #fff, 1505px 1491px #fff, 1223px 308px #fff, 1480px 1484px #fff, 503px 1702px #fff, 1739px 294px #fff, 1238px 252px #fff, 313px 730px #fff, 1497px 1752px #fff, 1859px 837px #fff,
    673px 1396px #fff, 567px 668px #fff, 556px 558px #fff, 827px 580px #fff, 363px 200px #fff, 1750px 505px #fff, 1402px 566px #fff, 501px 151px #fff, 1990px 1672px #fff, 191px 64px #fff, 569px 1560px #fff, 1778px 936px #fff, 1477px 1120px #fff, 1409px 1995px #fff, 728px 899px #fff, 1875px 1547px #fff, 534px 521px #fff, 1619px 1371px #fff, 280px 1640px #fff, 1577px 470px #fff, 1936px 900px #fff, 1336px 1119px #fff, 1213px 1172px #fff, 1359px 621px #fff, 1151px 1725px #fff, 482px 1709px #fff, 718px 348px #fff, 1915px 1937px #fff, 1872px 1473px #fff, 682px 278px #fff, 1303px 773px #fff, 455px 267px #fff, 1030px 1132px #fff, 287px 874px #fff, 1445px 1529px #fff, 204px 332px #fff, 1721px 1765px #fff, 948px 278px #fff, 713px 815px #fff, 1146px 268px #fff, 1823px 1112px #fff, 1242px 200px #fff, 1074px 176px #fff, 1510px 1138px #fff, 1616px 125px #fff, 208px 1898px #fff, 1496px 1937px #fff, 1493px 996px #fff, 1037px 1927px #fff, 1150px 192px #fff, 1559px 567px #fff, 665px 107px #fff,
    195px 695px #fff, 888px 934px #fff, 1386px 1572px #fff, 192px 1709px #fff, 552px 1429px #fff, 186px 1380px #fff, 1705px 497px #fff, 669px 1796px #fff, 1625px 1035px #fff, 876px 37px #fff, 1464px 1278px #fff, 1519px 1778px #fff, 243px 973px #fff, 1691px 909px #fff, 308px 4px #fff, 1713px 1356px #fff, 105px 1923px #fff, 1544px 1225px #fff, 1468px 1033px #fff, 1367px 176px #fff, 1967px 288px #fff, 1454px 1313px #fff, 1906px 1673px #fff, 638px 1620px #fff, 1967px 735px #fff, 1993px 1026px #fff, 20px 1386px #fff, 543px 1576px #fff, 590px 1404px #fff, 1058px 992px #fff, 1674px 1387px #fff, 613px 1625px #fff, 536px 1307px #fff, 1793px 1887px #fff, 111px 289px #fff, 1267px 1507px #fff, 1848px 1874px #fff, 7px 166px #fff, 119px 1148px #fff, 1340px 1790px #fff, 629px 1875px #fff, 101px 510px #fff, 965px 927px #fff, 1945px 1644px #fff, 1006px 1122px #fff, 340px 153px #fff, 267px 840px #fff, 1968px 1352px #fff, 1423px 725px #fff, 1569px 203px #fff, 1762px 1183px #fff, 1570px 1003px #fff,
    1561px 1798px #fff, 1348px 635px #fff, 1783px 1393px #fff, 1912px 798px #fff, 1295px 1302px #fff, 1450px 1835px #fff, 1803px 1246px #fff, 1260px 923px #fff, 807px 1975px #fff, 470px 719px #fff, 1267px 81px #fff, 482px 351px #fff, 595px 147px #fff, 1227px 1468px #fff, 1055px 1236px #fff, 1987px 761px #fff, 487px 1738px #fff, 342px 1785px #fff, 555px 45px #fff, 752px 1825px #fff, 2000px 471px #fff, 95px 178px #fff, 687px 75px #fff, 1666px 954px #fff, 996px 1074px #fff, 1233px 67px #fff, 1868px 1094px #fff, 286px 484px #fff, 255px 1654px #fff, 1136px 588px #fff, 1767px 1797px #fff, 580px 375px #fff, 1311px 1038px #fff, 1351px 1346px #fff, 1290px 1060px #fff, 1846px 602px #fff, 1813px 1159px #fff, 1903px 347px #fff, 1515px 1368px #fff, 883px 1152px #fff, 1630px 1470px #fff, 1961px 614px #fff, 1982px 127px #fff, 1053px 170px #fff, 650px 467px #fff, 1843px 953px #fff, 929px 1705px #fff, 1703px 881px #fff, 259px 377px #fff, 1932px 606px #fff, 937px 987px #fff, 29px 982px #fff,
    402px 897px #fff, 569px 191px #fff, 1842px 326px #fff, 1348px 708px #fff, 436px 1317px #fff, 832px 766px #fff, 284px 1343px #fff, 730px 326px #fff, 1861px 851px #fff, 1672px 13px #fff, 1511px 1081px #fff, 259px 1361px #fff, 1637px 632px #fff, 1560px 661px #fff, 1967px 1175px #fff, 854px 591px #fff, 1146px 1851px #fff, 1879px 799px #fff, 1034px 489px #fff, 1332px 314px #fff, 100px 778px #fff, 650px 1112px #fff, 1048px 692px #fff, 299px 900px #fff, 830px 769px #fff, 767px 662px #fff, 1792px 766px #fff, 1925px 457px #fff, 958px 51px #fff, 1143px 983px #fff, 439px 51px #fff, 532px 1217px #fff, 1565px 898px #fff, 669px 514px #fff, 1324px 1451px #fff, 1711px 833px #fff, 570px 85px #fff, 1680px 1345px #fff, 837px 423px #fff, 1545px 379px #fff, 285px 1768px #fff, 742px 1145px #fff, 874px 1396px #fff, 1226px 1585px #fff, 1764px 1073px #fff, 183px 1538px #fff, 466px 820px #fff, 923px 942px #fff, 512px 1746px #fff, 1607px 429px #fff, 1490px 933px #fff, 1733px 1087px #fff, 1128px 1219px #fff,
    1754px 745px #fff, 505px 234px #fff, 1037px 1710px #fff, 1383px 1690px #fff, 1740px 1125px #fff, 1053px 1846px #fff, 614px 1392px #fff, 1254px 344px #fff, 1410px 239px #fff, 61px 189px #fff, 1081px 1922px #fff, 208px 1968px #fff, 1237px 1781px #fff, 923px 1953px #fff, 1418px 554px #fff, 1457px 278px #fff, 1073px 1005px #fff, 1914px 1814px #fff, 1686px 1690px #fff, 1129px 61px #fff, 1407px 809px #fff, 675px 1944px #fff, 1833px 815px #fff, 1502px 62px #fff, 1678px 1649px #fff, 1986px 1775px #fff, 210px 1142px #fff, 110px 1157px #fff, 720px 1857px #fff, 394px 1120px #fff, 168px 559px #fff, 1321px 940px #fff, 1915px 1040px #fff, 1799px 834px #fff, 1346px 1514px #fff, 599px 268px #fff, 1637px 1533px #fff, 825px 1320px #fff, 1414px 661px #fff, 1125px 89px #fff, 1152px 1688px #fff, 1365px 1490px #fff, 653px 644px #fff, 1307px 1683px #fff, 1821px 1927px #fff, 1991px 242px #fff, 94px 456px #fff, 431px 421px #fff, 715px 961px #fff, 468px 19px #fff, 756px 972px #fff, 1602px 1554px #fff,
    1282px 51px #fff, 599px 1133px #fff, 489px 1230px #fff, 1090px 1416px #fff, 1772px 455px #fff, 909px 1528px #fff, 1946px 1104px #fff, 1523px 1274px #fff, 1804px 272px #fff, 1234px 955px #fff, 1809px 924px #fff, 1204px 1508px #fff, 1891px 175px #fff, 1154px 694px #fff, 1533px 524px #fff, 1271px 1389px #fff, 1515px 363px #fff, 1495px 1743px #fff, 1287px 160px #fff, 979px 1315px #fff, 507px 595px #fff, 1361px 1308px #fff, 316px 648px #fff, 1486px 1561px #fff, 173px 1601px #fff, 299px 1195px #fff, 1752px 1739px #fff, 349px 1417px #fff, 1131px 203px #fff, 1121px 1921px #fff, 1196px 652px #fff, 1161px 1483px #fff, 1597px 1570px #fff, 543px 1046px #fff, 101px 1083px #fff, 1891px 1283px #fff, 1371px 442px #fff, 1965px 844px #fff, 171px 1183px #fff, 1546px 798px #fff, 1738px 481px #fff, 1831px 1046px #fff, 590px 821px #fff, 1111px 1636px #fff, 898px 1577px #fff, 1080px 1179px #fff, 1279px 1980px #fff, 1171px 1969px #fff, 1952px 1514px #fff, 737px 1932px #fff, 512px 150px #fff,
    517px 298px #fff, 16px 1497px #fff, 165px 789px #fff, 461px 1497px #fff, 1439px 824px #fff, 1025px 1257px #fff, 1751px 1993px #fff, 1222px 1897px #fff, 1889px 377px #fff, 597px 1816px #fff, 309px 1319px #fff, 699px 791px #fff, 1160px 1713px #fff, 727px 1037px #fff, 1978px 1354px #fff, 131px 955px #fff, 915px 1727px #fff, 1404px 1288px #fff, 263px 135px #fff, 119px 578px #fff, 1345px 1843px #fff, 503px 1077px #fff, 966px 1574px #fff, 342px 1791px #fff, 1019px 1806px #fff, 1088px 1021px #fff, 4px 1391px #fff, 1957px 1259px #fff, 1214px 1770px #fff, 1285px 1323px #fff, 113px 1353px #fff, 1817px 1640px #fff, 755px 1269px #fff, 1643px 531px #fff, 1800px 1893px #fff, 434px 1621px #fff, 612px 156px #fff, 149px 1105px #fff, 427px 1976px #fff, 798px 317px #fff, 737px 241px #fff, 1868px 639px #fff, 1973px 665px #fff, 1715px 871px #fff, 1177px 152px #fff, 625px 975px #fff, 1253px 594px #fff, 185px 1621px #fff, 204px 505px #fff, 1766px 1344px #fff, 455px 74px #fff, 1103px 739px #fff,
    587px 345px #fff, 1322px 894px #fff, 747px 1937px #fff, 543px 634px #fff, 1294px 764px #fff, 1053px 323px #fff, 43px 1035px #fff, 242px 1889px #fff, 308px 1344px #fff, 406px 1437px #fff, 1632px 763px #fff, 161px 220px #fff, 1506px 437px #fff, 605px 1491px #fff, 913px 1154px #fff, 645px 913px #fff, 1511px 895px #fff, 1739px 856px #fff, 545px 1093px #fff, 736px 1999px #fff, 636px 582px #fff, 1103px 1794px #fff, 444px 808px #fff, 1068px 1443px #fff, 660px 854px #fff, 1013px 495px #fff, 359px 838px #fff, 1811px 1449px #fff, 1954px 1431px #fff, 2px 357px #fff, 1063px 1476px #fff, 1134px 461px #fff, 61px 452px #fff, 55px 56px #fff, 626px 636px #fff, 489px 548px #fff, 1075px 583px #fff, 1075px 756px #fff, 442px 1066px #fff, 1862px 1572px #fff, 904px 1547px #fff, 1477px 258px #fff, 382px 981px #fff, 1224px 685px #fff, 1821px 902px #fff, 1066px 1219px #fff, 894px 1343px #fff, 1861px 502px #fff, 1030px 892px #fff, 681px 496px #fff, 1589px 694px #fff, 1357px 972px #fff, 110px 1090px #fff,
    211px 1312px #fff, 354px 398px #fff, 338px 1388px #fff, 71px 964px #fff, 413px 42px #fff, 783px 1081px #fff, 1997px 678px #fff, 1871px 1298px #fff, 349px 1165px #fff, 1315px 1108px #fff, 1632px 1041px #fff, 1046px 809px #fff, 1839px 687px #fff, 1911px 1012px #fff, 1029px 1681px #fff, 1313px 560px #fff, 507px 1964px #fff, 97px 1627px #fff, 457px 1464px #fff, 433px 1638px #fff, 1107px 647px #fff, 951px 1652px #fff, 131px 490px #fff, 1092px 1005px #fff, 325px 133px #fff, 45px 804px #fff, 1188px 1746px #fff, 905px 458px #fff, 910px 481px #fff, 1579px 509px #fff, 1569px 12px #fff, 575px 226px #fff, 1725px 430px #fff, 1053px 44px #fff, 417px 1123px #fff, 1720px 1410px #fff, 37px 1543px #fff, 388px 408px #fff, 263px 97px #fff, 556px 1182px #fff, 1834px 503px #fff, 671px 1121px #fff, 673px 119px #fff, 215px 819px #fff, 869px 1585px #fff, 1121px 469px #fff, 595px 1499px #fff, 1931px 1645px #fff, 402px 1856px #fff, 1866px 1654px #fff, 158px 258px #fff, 708px 1328px #fff, 1206px 1347px #fff,
    1305px 121px #fff, 1246px 1526px #fff, 765px 289px #fff, 1692px 1757px #fff, 549px 600px #fff, 1909px 1362px #fff, 24px 1615px #fff, 314px 666px #fff, 1320px 622px #fff, 1508px 1223px #fff, 1583px 1402px #fff, 617px 1456px #fff, 308px 217px #fff, 1362px 1455px #fff, 192px 1870px #fff, 79px 1069px #fff, 340px 2px #fff, 498px 1123px #fff, 973px 1765px #fff, 1289px 1378px #fff, 986px 1478px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 357px 748px #fff, 1626px 800px #fff, 357px 1286px #fff, 1027px 721px #fff, 1754px 224px #fff, 1560px 769px #fff, 881px 1605px #fff, 650px 1228px #fff, 1992px 1304px #fff, 1862px 1101px #fff, 1613px 738px #fff, 650px 436px #fff, 1461px 1062px #fff, 741px 1525px #fff, 1321px 759px #fff, 1002px 346px #fff, 1360px 765px #fff, 1858px 461px #fff, 543px 15px #fff, 1081px 1747px #fff, 17px 1654px #fff, 1988px 319px #fff, 1695px 1911px #fff, 606px 1802px #fff, 1883px 1127px #fff, 1627px 1841px #fff, 98px 922px #fff, 1090px 688px #fff, 568px 701px #fff, 767px 469px #fff, 1668px 1597px #fff, 816px 1730px #fff, 469px 1848px #fff, 310px 33px #fff, 1390px 593px #fff, 143px 582px #fff, 1769px 1251px #fff, 1327px 1263px #fff, 364px 999px #fff, 1531px 1651px #fff, 1967px 1862px #fff, 416px 1241px #fff, 601px 970px #fff, 1759px 68px #fff, 1093px 66px #fff, 1963px 519px #fff, 1396px 1032px #fff, 1689px 319px #fff, 1696px 1116px #fff, 165px 122px #fff, 238px 1673px #fff, 1165px 510px #fff,
    260px 657px #fff, 131px 207px #fff, 1976px 756px #fff, 98px 1025px #fff, 1971px 677px #fff, 517px 1771px #fff, 313px 1267px #fff, 1917px 209px #fff, 1044px 1181px #fff, 1663px 325px #fff, 143px 856px #fff, 1449px 792px #fff, 1153px 262px #fff, 12px 1697px #fff, 1937px 824px #fff, 525px 1189px #fff, 1871px 1670px #fff, 1440px 1191px #fff, 178px 1273px #fff, 1898px 1788px #fff, 722px 927px #fff, 305px 1404px #fff, 1737px 92px #fff, 845px 702px #fff, 1356px 221px #fff, 1974px 385px #fff, 621px 1684px #fff, 438px 1676px #fff, 355px 772px #fff, 535px 480px #fff, 511px 489px #fff, 337px 545px #fff, 1039px 1527px #fff, 1860px 912px #fff, 168px 1959px #fff, 1855px 1667px #fff, 1474px 1914px #fff, 1553px 1038px #fff, 514px 1478px #fff, 1900px 1036px #fff, 330px 1639px #fff, 1944px 1470px #fff, 1596px 583px #fff, 965px 1492px #fff, 746px 233px #fff, 761px 58px #fff, 1690px 1381px #fff, 1736px 1733px #fff, 1627px 1043px #fff, 365px 782px #fff, 286px 1486px #fff, 1251px 1356px #fff,
    1297px 696px #fff, 1702px 1686px #fff, 391px 1506px #fff, 1536px 1392px #fff, 90px 678px #fff, 51px 646px #fff, 958px 1808px #fff, 1078px 526px #fff, 698px 465px #fff, 590px 1603px #fff, 190px 1496px #fff, 1600px 1885px #fff, 656px 844px #fff, 1556px 155px #fff, 356px 5px #fff, 579px 663px #fff, 728px 367px #fff, 1054px 657px #fff, 677px 1992px #fff, 1498px 395px #fff, 1744px 324px #fff, 1677px 1538px #fff, 969px 1409px #fff, 1044px 831px #fff, 124px 915px #fff, 762px 1985px #fff, 1894px 1440px #fff, 6px 1755px #fff, 215px 1780px #fff, 1304px 1290px #fff, 673px 396px #fff, 583px 1086px #fff, 1416px 1915px #fff, 1295px 327px #fff, 1437px 493px #fff, 1775px 390px #fff, 1889px 744px #fff, 592px 758px #fff, 19px 732px #fff, 268px 1537px #fff, 1941px 1769px #fff, 1565px 1209px #fff, 469px 46px #fff, 416px 404px #fff, 416px 1603px #fff, 1064px 136px #fff, 1252px 798px #fff, 14px 1100px #fff, 758px 944px #fff, 1827px 1841px #fff, 286px 176px #fff, 451px 1818px #fff, 1398px 1483px #fff,
    1002px 1034px #fff, 709px 877px #fff, 802px 680px #fff, 488px 1675px #fff, 1576px 413px #fff, 323px 1157px #fff, 287px 920px #fff, 709px 1324px #fff, 1745px 1613px #fff, 943px 1687px #fff, 329px 1208px #fff, 401px 1085px #fff, 577px 1117px #fff, 1736px 354px #fff, 719px 557px #fff, 1292px 1003px #fff, 323px 1841px #fff, 1505px 1151px #fff, 1360px 1px #fff, 520px 1271px #fff, 1586px 916px #fff, 707px 582px #fff, 1307px 1501px #fff, 1124px 914px #fff, 737px 41px #fff, 1497px 1397px #fff, 670px 1016px #fff, 687px 1882px #fff, 107px 950px #fff, 1325px 7px #fff, 998px 327px #fff, 827px 1698px #fff, 251px 933px #fff, 683px 1936px #fff, 1992px 905px #fff, 1024px 399px #fff, 433px 1211px #fff, 830px 1919px #fff, 1980px 946px #fff, 1842px 770px #fff, 314px 1899px #fff, 573px 1027px #fff, 1633px 606px #fff, 1505px 1491px #fff, 1223px 308px #fff, 1480px 1484px #fff, 503px 1702px #fff, 1739px 294px #fff, 1238px 252px #fff, 313px 730px #fff, 1497px 1752px #fff, 1859px 837px #fff,
    673px 1396px #fff, 567px 668px #fff, 556px 558px #fff, 827px 580px #fff, 363px 200px #fff, 1750px 505px #fff, 1402px 566px #fff, 501px 151px #fff, 1990px 1672px #fff, 191px 64px #fff, 569px 1560px #fff, 1778px 936px #fff, 1477px 1120px #fff, 1409px 1995px #fff, 728px 899px #fff, 1875px 1547px #fff, 534px 521px #fff, 1619px 1371px #fff, 280px 1640px #fff, 1577px 470px #fff, 1936px 900px #fff, 1336px 1119px #fff, 1213px 1172px #fff, 1359px 621px #fff, 1151px 1725px #fff, 482px 1709px #fff, 718px 348px #fff, 1915px 1937px #fff, 1872px 1473px #fff, 682px 278px #fff, 1303px 773px #fff, 455px 267px #fff, 1030px 1132px #fff, 287px 874px #fff, 1445px 1529px #fff, 204px 332px #fff, 1721px 1765px #fff, 948px 278px #fff, 713px 815px #fff, 1146px 268px #fff, 1823px 1112px #fff, 1242px 200px #fff, 1074px 176px #fff, 1510px 1138px #fff, 1616px 125px #fff, 208px 1898px #fff, 1496px 1937px #fff, 1493px 996px #fff, 1037px 1927px #fff, 1150px 192px #fff, 1559px 567px #fff, 665px 107px #fff,
    195px 695px #fff, 888px 934px #fff, 1386px 1572px #fff, 192px 1709px #fff, 552px 1429px #fff, 186px 1380px #fff, 1705px 497px #fff, 669px 1796px #fff, 1625px 1035px #fff, 876px 37px #fff, 1464px 1278px #fff, 1519px 1778px #fff, 243px 973px #fff, 1691px 909px #fff, 308px 4px #fff, 1713px 1356px #fff, 105px 1923px #fff, 1544px 1225px #fff, 1468px 1033px #fff, 1367px 176px #fff, 1967px 288px #fff, 1454px 1313px #fff, 1906px 1673px #fff, 638px 1620px #fff, 1967px 735px #fff, 1993px 1026px #fff, 20px 1386px #fff, 543px 1576px #fff, 590px 1404px #fff, 1058px 992px #fff, 1674px 1387px #fff, 613px 1625px #fff, 536px 1307px #fff, 1793px 1887px #fff, 111px 289px #fff, 1267px 1507px #fff, 1848px 1874px #fff, 7px 166px #fff, 119px 1148px #fff, 1340px 1790px #fff, 629px 1875px #fff, 101px 510px #fff, 965px 927px #fff, 1945px 1644px #fff, 1006px 1122px #fff, 340px 153px #fff, 267px 840px #fff, 1968px 1352px #fff, 1423px 725px #fff, 1569px 203px #fff, 1762px 1183px #fff, 1570px 1003px #fff,
    1561px 1798px #fff, 1348px 635px #fff, 1783px 1393px #fff, 1912px 798px #fff, 1295px 1302px #fff, 1450px 1835px #fff, 1803px 1246px #fff, 1260px 923px #fff, 807px 1975px #fff, 470px 719px #fff, 1267px 81px #fff, 482px 351px #fff, 595px 147px #fff, 1227px 1468px #fff, 1055px 1236px #fff, 1987px 761px #fff, 487px 1738px #fff, 342px 1785px #fff, 555px 45px #fff, 752px 1825px #fff, 2000px 471px #fff, 95px 178px #fff, 687px 75px #fff, 1666px 954px #fff, 996px 1074px #fff, 1233px 67px #fff, 1868px 1094px #fff, 286px 484px #fff, 255px 1654px #fff, 1136px 588px #fff, 1767px 1797px #fff, 580px 375px #fff, 1311px 1038px #fff, 1351px 1346px #fff, 1290px 1060px #fff, 1846px 602px #fff, 1813px 1159px #fff, 1903px 347px #fff, 1515px 1368px #fff, 883px 1152px #fff, 1630px 1470px #fff, 1961px 614px #fff, 1982px 127px #fff, 1053px 170px #fff, 650px 467px #fff, 1843px 953px #fff, 929px 1705px #fff, 1703px 881px #fff, 259px 377px #fff, 1932px 606px #fff, 937px 987px #fff, 29px 982px #fff,
    402px 897px #fff, 569px 191px #fff, 1842px 326px #fff, 1348px 708px #fff, 436px 1317px #fff, 832px 766px #fff, 284px 1343px #fff, 730px 326px #fff, 1861px 851px #fff, 1672px 13px #fff, 1511px 1081px #fff, 259px 1361px #fff, 1637px 632px #fff, 1560px 661px #fff, 1967px 1175px #fff, 854px 591px #fff, 1146px 1851px #fff, 1879px 799px #fff, 1034px 489px #fff, 1332px 314px #fff, 100px 778px #fff, 650px 1112px #fff, 1048px 692px #fff, 299px 900px #fff, 830px 769px #fff, 767px 662px #fff, 1792px 766px #fff, 1925px 457px #fff, 958px 51px #fff, 1143px 983px #fff, 439px 51px #fff, 532px 1217px #fff, 1565px 898px #fff, 669px 514px #fff, 1324px 1451px #fff, 1711px 833px #fff, 570px 85px #fff, 1680px 1345px #fff, 837px 423px #fff, 1545px 379px #fff, 285px 1768px #fff, 742px 1145px #fff, 874px 1396px #fff, 1226px 1585px #fff, 1764px 1073px #fff, 183px 1538px #fff, 466px 820px #fff, 923px 942px #fff, 512px 1746px #fff, 1607px 429px #fff, 1490px 933px #fff, 1733px 1087px #fff, 1128px 1219px #fff,
    1754px 745px #fff, 505px 234px #fff, 1037px 1710px #fff, 1383px 1690px #fff, 1740px 1125px #fff, 1053px 1846px #fff, 614px 1392px #fff, 1254px 344px #fff, 1410px 239px #fff, 61px 189px #fff, 1081px 1922px #fff, 208px 1968px #fff, 1237px 1781px #fff, 923px 1953px #fff, 1418px 554px #fff, 1457px 278px #fff, 1073px 1005px #fff, 1914px 1814px #fff, 1686px 1690px #fff, 1129px 61px #fff, 1407px 809px #fff, 675px 1944px #fff, 1833px 815px #fff, 1502px 62px #fff, 1678px 1649px #fff, 1986px 1775px #fff, 210px 1142px #fff, 110px 1157px #fff, 720px 1857px #fff, 394px 1120px #fff, 168px 559px #fff, 1321px 940px #fff, 1915px 1040px #fff, 1799px 834px #fff, 1346px 1514px #fff, 599px 268px #fff, 1637px 1533px #fff, 825px 1320px #fff, 1414px 661px #fff, 1125px 89px #fff, 1152px 1688px #fff, 1365px 1490px #fff, 653px 644px #fff, 1307px 1683px #fff, 1821px 1927px #fff, 1991px 242px #fff, 94px 456px #fff, 431px 421px #fff, 715px 961px #fff, 468px 19px #fff, 756px 972px #fff, 1602px 1554px #fff,
    1282px 51px #fff, 599px 1133px #fff, 489px 1230px #fff, 1090px 1416px #fff, 1772px 455px #fff, 909px 1528px #fff, 1946px 1104px #fff, 1523px 1274px #fff, 1804px 272px #fff, 1234px 955px #fff, 1809px 924px #fff, 1204px 1508px #fff, 1891px 175px #fff, 1154px 694px #fff, 1533px 524px #fff, 1271px 1389px #fff, 1515px 363px #fff, 1495px 1743px #fff, 1287px 160px #fff, 979px 1315px #fff, 507px 595px #fff, 1361px 1308px #fff, 316px 648px #fff, 1486px 1561px #fff, 173px 1601px #fff, 299px 1195px #fff, 1752px 1739px #fff, 349px 1417px #fff, 1131px 203px #fff, 1121px 1921px #fff, 1196px 652px #fff, 1161px 1483px #fff, 1597px 1570px #fff, 543px 1046px #fff, 101px 1083px #fff, 1891px 1283px #fff, 1371px 442px #fff, 1965px 844px #fff, 171px 1183px #fff, 1546px 798px #fff, 1738px 481px #fff, 1831px 1046px #fff, 590px 821px #fff, 1111px 1636px #fff, 898px 1577px #fff, 1080px 1179px #fff, 1279px 1980px #fff, 1171px 1969px #fff, 1952px 1514px #fff, 737px 1932px #fff, 512px 150px #fff,
    517px 298px #fff, 16px 1497px #fff, 165px 789px #fff, 461px 1497px #fff, 1439px 824px #fff, 1025px 1257px #fff, 1751px 1993px #fff, 1222px 1897px #fff, 1889px 377px #fff, 597px 1816px #fff, 309px 1319px #fff, 699px 791px #fff, 1160px 1713px #fff, 727px 1037px #fff, 1978px 1354px #fff, 131px 955px #fff, 915px 1727px #fff, 1404px 1288px #fff, 263px 135px #fff, 119px 578px #fff, 1345px 1843px #fff, 503px 1077px #fff, 966px 1574px #fff, 342px 1791px #fff, 1019px 1806px #fff, 1088px 1021px #fff, 4px 1391px #fff, 1957px 1259px #fff, 1214px 1770px #fff, 1285px 1323px #fff, 113px 1353px #fff, 1817px 1640px #fff, 755px 1269px #fff, 1643px 531px #fff, 1800px 1893px #fff, 434px 1621px #fff, 612px 156px #fff, 149px 1105px #fff, 427px 1976px #fff, 798px 317px #fff, 737px 241px #fff, 1868px 639px #fff, 1973px 665px #fff, 1715px 871px #fff, 1177px 152px #fff, 625px 975px #fff, 1253px 594px #fff, 185px 1621px #fff, 204px 505px #fff, 1766px 1344px #fff, 455px 74px #fff, 1103px 739px #fff,
    587px 345px #fff, 1322px 894px #fff, 747px 1937px #fff, 543px 634px #fff, 1294px 764px #fff, 1053px 323px #fff, 43px 1035px #fff, 242px 1889px #fff, 308px 1344px #fff, 406px 1437px #fff, 1632px 763px #fff, 161px 220px #fff, 1506px 437px #fff, 605px 1491px #fff, 913px 1154px #fff, 645px 913px #fff, 1511px 895px #fff, 1739px 856px #fff, 545px 1093px #fff, 736px 1999px #fff, 636px 582px #fff, 1103px 1794px #fff, 444px 808px #fff, 1068px 1443px #fff, 660px 854px #fff, 1013px 495px #fff, 359px 838px #fff, 1811px 1449px #fff, 1954px 1431px #fff, 2px 357px #fff, 1063px 1476px #fff, 1134px 461px #fff, 61px 452px #fff, 55px 56px #fff, 626px 636px #fff, 489px 548px #fff, 1075px 583px #fff, 1075px 756px #fff, 442px 1066px #fff, 1862px 1572px #fff, 904px 1547px #fff, 1477px 258px #fff, 382px 981px #fff, 1224px 685px #fff, 1821px 902px #fff, 1066px 1219px #fff, 894px 1343px #fff, 1861px 502px #fff, 1030px 892px #fff, 681px 496px #fff, 1589px 694px #fff, 1357px 972px #fff, 110px 1090px #fff,
    211px 1312px #fff, 354px 398px #fff, 338px 1388px #fff, 71px 964px #fff, 413px 42px #fff, 783px 1081px #fff, 1997px 678px #fff, 1871px 1298px #fff, 349px 1165px #fff, 1315px 1108px #fff, 1632px 1041px #fff, 1046px 809px #fff, 1839px 687px #fff, 1911px 1012px #fff, 1029px 1681px #fff, 1313px 560px #fff, 507px 1964px #fff, 97px 1627px #fff, 457px 1464px #fff, 433px 1638px #fff, 1107px 647px #fff, 951px 1652px #fff, 131px 490px #fff, 1092px 1005px #fff, 325px 133px #fff, 45px 804px #fff, 1188px 1746px #fff, 905px 458px #fff, 910px 481px #fff, 1579px 509px #fff, 1569px 12px #fff, 575px 226px #fff, 1725px 430px #fff, 1053px 44px #fff, 417px 1123px #fff, 1720px 1410px #fff, 37px 1543px #fff, 388px 408px #fff, 263px 97px #fff, 556px 1182px #fff, 1834px 503px #fff, 671px 1121px #fff, 673px 119px #fff, 215px 819px #fff, 869px 1585px #fff, 1121px 469px #fff, 595px 1499px #fff, 1931px 1645px #fff, 402px 1856px #fff, 1866px 1654px #fff, 158px 258px #fff, 708px 1328px #fff, 1206px 1347px #fff,
    1305px 121px #fff, 1246px 1526px #fff, 765px 289px #fff, 1692px 1757px #fff, 549px 600px #fff, 1909px 1362px #fff, 24px 1615px #fff, 314px 666px #fff, 1320px 622px #fff, 1508px 1223px #fff, 1583px 1402px #fff, 617px 1456px #fff, 308px 217px #fff, 1362px 1455px #fff, 192px 1870px #fff, 79px 1069px #fff, 340px 2px #fff, 498px 1123px #fff, 973px 1765px #fff, 1289px 1378px #fff, 986px 1478px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 905px 1765px #fff, 1417px 1128px #fff, 1647px 1679px #fff, 747px 1187px #fff, 1252px 503px #fff, 186px 1458px #fff, 1025px 773px #fff, 881px 534px #fff, 334px 1386px #fff, 1490px 1644px #fff, 300px 1889px #fff, 908px 310px #fff, 932px 1286px #fff, 1683px 476px #fff, 32px 1525px #fff, 993px 223px #fff, 240px 1314px #fff, 1094px 389px #fff, 54px 1966px #fff, 148px 1851px #fff, 1475px 1555px #fff, 1029px 1863px #fff, 1976px 654px #fff, 435px 78px #fff, 417px 745px #fff, 511px 1188px #fff, 1155px 335px #fff, 965px 1751px #fff, 1073px 1076px #fff, 1089px 1850px #fff, 1317px 1238px #fff, 148px 829px #fff, 1391px 1638px #fff, 1370px 918px #fff, 1187px 213px #fff, 961px 849px #fff, 667px 374px #fff, 1111px 684px #fff, 295px 1773px #fff, 514px 122px #fff, 1560px 542px #fff, 458px 1868px #fff, 1065px 920px #fff, 1923px 59px #fff, 875px 125px #fff, 1313px 1899px #fff, 137px 542px #fff, 1073px 283px #fff, 1807px 128px #fff, 1656px 1414px #fff, 1794px 1342px #fff, 1561px 1647px #fff,
    104px 633px #fff, 540px 158px #fff, 725px 1832px #fff, 257px 1489px #fff, 548px 1615px #fff, 1502px 1841px #fff, 1716px 40px #fff, 1979px 908px #fff, 682px 975px #fff, 1861px 1951px #fff, 790px 1762px #fff, 888px 5px #fff, 584px 1685px #fff, 1713px 107px #fff, 1413px 671px #fff, 735px 943px #fff, 1559px 1104px #fff, 1692px 266px #fff, 1182px 974px #fff, 1898px 1266px #fff, 682px 1260px #fff, 279px 1313px #fff, 1014px 1902px #fff, 521px 1434px #fff, 1793px 1116px #fff, 539px 1588px #fff, 496px 1948px #fff, 575px 433px #fff, 818px 1166px #fff, 1998px 1161px #fff, 518px 568px #fff, 1500px 572px #fff, 122px 131px #fff, 1358px 1902px #fff, 605px 1710px #fff, 386px 1817px #fff, 1943px 197px #fff, 1086px 1902px #fff, 860px 1729px #fff, 1969px 1948px #fff, 1686px 1893px #fff, 1393px 1110px #fff, 1587px 1560px #fff, 1672px 165px #fff, 1007px 345px #fff, 977px 359px #fff, 1029px 1406px #fff, 308px 1361px #fff, 1010px 87px #fff, 1509px 116px #fff, 264px 1564px #fff, 1318px 551px #fff,
    620px 1488px #fff, 1748px 132px #fff, 558px 849px #fff, 1130px 11px #fff, 1811px 1697px #fff, 1709px 467px #fff, 1286px 1071px #fff, 676px 687px #fff, 1001px 1px #fff, 344px 1179px #fff, 1465px 1183px #fff, 371px 987px #fff, 1446px 1233px #fff, 1354px 107px #fff, 689px 1102px #fff, 155px 343px #fff, 1062px 1804px #fff, 467px 1333px #fff, 173px 151px #fff, 1022px 64px #fff, 308px 314px #fff, 1013px 1647px #fff, 271px 398px #fff, 1011px 823px #fff, 1299px 719px #fff, 81px 1448px #fff, 1495px 445px #fff, 530px 1861px #fff, 495px 181px #fff, 1896px 395px #fff, 840px 886px #fff, 203px 437px #fff, 305px 1047px #fff, 1196px 420px #fff, 1534px 1026px #fff, 1180px 1493px #fff, 1908px 806px #fff, 1295px 1867px #fff, 809px 122px #fff, 1611px 1919px #fff, 1992px 135px #fff, 275px 759px #fff, 1368px 1395px #fff, 1169px 751px #fff, 1746px 326px #fff, 1582px 827px #fff, 1163px 1243px #fff, 1217px 1465px #fff, 226px 32px #fff, 590px 383px #fff, 1874px 950px #fff, 1749px 190px #fff,
    1902px 627px #fff, 1723px 1223px #fff, 1840px 1562px #fff, 1826px 11px #fff, 89px 867px #fff, 1972px 432px #fff, 238px 1707px #fff, 911px 921px #fff, 642px 1114px #fff, 1503px 118px #fff, 881px 245px #fff, 498px 1673px #fff, 25px 1476px #fff, 1652px 443px #fff, 1487px 252px #fff, 210px 1098px #fff, 1255px 1988px #fff, 191px 1143px #fff, 753px 600px #fff, 1891px 859px #fff, 610px 531px #fff, 831px 1064px #fff, 207px 1890px #fff, 1252px 1906px #fff, 641px 1580px #fff, 1730px 436px #fff, 1230px 1128px #fff, 1797px 473px #fff, 83px 1669px #fff, 548px 260px #fff, 352px 1652px #fff, 18px 1052px #fff, 1815px 68px #fff, 1085px 1326px #fff, 36px 393px #fff, 660px 954px #fff, 1668px 1518px #fff, 95px 219px #fff, 29px 1519px #fff, 1665px 1724px #fff, 430px 1099px #fff, 294px 206px #fff, 354px 723px #fff, 148px 1277px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 905px 1765px #fff, 1417px 1128px #fff, 1647px 1679px #fff, 747px 1187px #fff, 1252px 503px #fff, 186px 1458px #fff, 1025px 773px #fff, 881px 534px #fff, 334px 1386px #fff, 1490px 1644px #fff, 300px 1889px #fff, 908px 310px #fff, 932px 1286px #fff, 1683px 476px #fff, 32px 1525px #fff, 993px 223px #fff, 240px 1314px #fff, 1094px 389px #fff, 54px 1966px #fff, 148px 1851px #fff, 1475px 1555px #fff, 1029px 1863px #fff, 1976px 654px #fff, 435px 78px #fff, 417px 745px #fff, 511px 1188px #fff, 1155px 335px #fff, 965px 1751px #fff, 1073px 1076px #fff, 1089px 1850px #fff, 1317px 1238px #fff, 148px 829px #fff, 1391px 1638px #fff, 1370px 918px #fff, 1187px 213px #fff, 961px 849px #fff, 667px 374px #fff, 1111px 684px #fff, 295px 1773px #fff, 514px 122px #fff, 1560px 542px #fff, 458px 1868px #fff, 1065px 920px #fff, 1923px 59px #fff, 875px 125px #fff, 1313px 1899px #fff, 137px 542px #fff, 1073px 283px #fff, 1807px 128px #fff, 1656px 1414px #fff, 1794px 1342px #fff, 1561px 1647px #fff,
    104px 633px #fff, 540px 158px #fff, 725px 1832px #fff, 257px 1489px #fff, 548px 1615px #fff, 1502px 1841px #fff, 1716px 40px #fff, 1979px 908px #fff, 682px 975px #fff, 1861px 1951px #fff, 790px 1762px #fff, 888px 5px #fff, 584px 1685px #fff, 1713px 107px #fff, 1413px 671px #fff, 735px 943px #fff, 1559px 1104px #fff, 1692px 266px #fff, 1182px 974px #fff, 1898px 1266px #fff, 682px 1260px #fff, 279px 1313px #fff, 1014px 1902px #fff, 521px 1434px #fff, 1793px 1116px #fff, 539px 1588px #fff, 496px 1948px #fff, 575px 433px #fff, 818px 1166px #fff, 1998px 1161px #fff, 518px 568px #fff, 1500px 572px #fff, 122px 131px #fff, 1358px 1902px #fff, 605px 1710px #fff, 386px 1817px #fff, 1943px 197px #fff, 1086px 1902px #fff, 860px 1729px #fff, 1969px 1948px #fff, 1686px 1893px #fff, 1393px 1110px #fff, 1587px 1560px #fff, 1672px 165px #fff, 1007px 345px #fff, 977px 359px #fff, 1029px 1406px #fff, 308px 1361px #fff, 1010px 87px #fff, 1509px 116px #fff, 264px 1564px #fff, 1318px 551px #fff,
    620px 1488px #fff, 1748px 132px #fff, 558px 849px #fff, 1130px 11px #fff, 1811px 1697px #fff, 1709px 467px #fff, 1286px 1071px #fff, 676px 687px #fff, 1001px 1px #fff, 344px 1179px #fff, 1465px 1183px #fff, 371px 987px #fff, 1446px 1233px #fff, 1354px 107px #fff, 689px 1102px #fff, 155px 343px #fff, 1062px 1804px #fff, 467px 1333px #fff, 173px 151px #fff, 1022px 64px #fff, 308px 314px #fff, 1013px 1647px #fff, 271px 398px #fff, 1011px 823px #fff, 1299px 719px #fff, 81px 1448px #fff, 1495px 445px #fff, 530px 1861px #fff, 495px 181px #fff, 1896px 395px #fff, 840px 886px #fff, 203px 437px #fff, 305px 1047px #fff, 1196px 420px #fff, 1534px 1026px #fff, 1180px 1493px #fff, 1908px 806px #fff, 1295px 1867px #fff, 809px 122px #fff, 1611px 1919px #fff, 1992px 135px #fff, 275px 759px #fff, 1368px 1395px #fff, 1169px 751px #fff, 1746px 326px #fff, 1582px 827px #fff, 1163px 1243px #fff, 1217px 1465px #fff, 226px 32px #fff, 590px 383px #fff, 1874px 950px #fff, 1749px 190px #fff,
    1902px 627px #fff, 1723px 1223px #fff, 1840px 1562px #fff, 1826px 11px #fff, 89px 867px #fff, 1972px 432px #fff, 238px 1707px #fff, 911px 921px #fff, 642px 1114px #fff, 1503px 118px #fff, 881px 245px #fff, 498px 1673px #fff, 25px 1476px #fff, 1652px 443px #fff, 1487px 252px #fff, 210px 1098px #fff, 1255px 1988px #fff, 191px 1143px #fff, 753px 600px #fff, 1891px 859px #fff, 610px 531px #fff, 831px 1064px #fff, 207px 1890px #fff, 1252px 1906px #fff, 641px 1580px #fff, 1730px 436px #fff, 1230px 1128px #fff, 1797px 473px #fff, 83px 1669px #fff, 548px 260px #fff, 352px 1652px #fff, 18px 1052px #fff, 1815px 68px #fff, 1085px 1326px #fff, 36px 393px #fff, 660px 954px #fff, 1668px 1518px #fff, 95px 219px #fff, 29px 1519px #fff, 1665px 1724px #fff, 430px 1099px #fff, 294px 206px #fff, 354px 723px #fff, 148px 1277px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 374px 1575px #fff, 468px 20px #fff, 1136px 1165px #fff, 650px 561px #fff, 1192px 755px #fff, 881px 622px #fff, 687px 1578px #fff, 521px 1251px #fff, 645px 358px #fff, 521px 1484px #fff, 736px 815px #fff, 293px 1916px #fff, 66px 1847px #fff, 988px 493px #fff, 515px 1827px #fff, 1407px 1245px #fff, 11px 482px #fff, 50px 365px #fff, 1768px 316px #fff, 67px 143px #fff, 1030px 1211px #fff, 862px 669px #fff, 1474px 796px #fff, 1721px 39px #fff, 862px 774px #fff, 793px 195px #fff, 749px 742px #fff, 164px 1863px #fff, 137px 769px #fff, 576px 563px #fff, 719px 93px #fff, 667px 1478px #fff, 28px 650px #fff, 660px 1436px #fff, 917px 1658px #fff, 1548px 222px #fff, 313px 1330px #fff, 955px 475px #fff, 1972px 643px #fff, 320px 1959px #fff, 1621px 398px #fff, 1161px 127px #fff, 1329px 231px #fff, 14px 816px #fff, 71px 1247px #fff, 1501px 1060px #fff, 1288px 1087px #fff, 1572px 173px #fff, 1419px 41px #fff, 728px 1360px #fff, 1326px 1729px #fff, 1237px 942px #fff, 1362px 1321px #fff,
    1043px 12px #fff, 1535px 1069px #fff, 160px 49px #fff, 1659px 297px #fff, 833px 808px #fff, 1195px 524px #fff, 1758px 64px #fff, 1589px 14px #fff, 502px 388px #fff, 652px 563px #fff, 70px 603px #fff, 236px 360px #fff, 162px 452px #fff, 1377px 1826px #fff, 284px 521px #fff, 707px 1308px #fff, 1985px 1193px #fff, 1017px 18px #fff, 363px 1169px #fff, 1579px 503px #fff, 1538px 361px #fff, 256px 170px #fff, 1814px 41px #fff, 163px 991px #fff, 1251px 1391px #fff, 578px 1577px #fff, 1227px 223px #fff, 1781px 1507px #fff, 1111px 1906px #fff, 745px 1631px #fff, 1942px 469px #fff, 1396px 1159px #fff, 985px 1070px #fff, 1856px 490px #fff, 210px 1701px #fff, 1091px 655px #fff, 191px 368px #fff, 755px 1454px #fff, 908px 117px #fff, 1943px 1802px #fff, 1464px 1378px #fff, 1419px 1095px #fff, 143px 1638px #fff, 533px 888px #fff, 292px 261px #fff, 1445px 630px #fff, 1311px 1046px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 374px 1575px #fff, 468px 20px #fff, 1136px 1165px #fff, 650px 561px #fff, 1192px 755px #fff, 881px 622px #fff, 687px 1578px #fff, 521px 1251px #fff, 645px 358px #fff, 521px 1484px #fff, 736px 815px #fff, 293px 1916px #fff, 66px 1847px #fff, 988px 493px #fff, 515px 1827px #fff, 1407px 1245px #fff, 11px 482px #fff, 50px 365px #fff, 1768px 316px #fff, 67px 143px #fff, 1030px 1211px #fff, 862px 669px #fff, 1474px 796px #fff, 1721px 39px #fff, 862px 774px #fff, 793px 195px #fff, 749px 742px #fff, 164px 1863px #fff, 137px 769px #fff, 576px 563px #fff, 719px 93px #fff, 667px 1478px #fff, 28px 650px #fff, 660px 1436px #fff, 917px 1658px #fff, 1548px 222px #fff, 313px 1330px #fff, 955px 475px #fff, 1972px 643px #fff, 320px 1959px #fff, 1621px 398px #fff, 1161px 127px #fff, 1329px 231px #fff, 14px 816px #fff, 71px 1247px #fff, 1501px 1060px #fff, 1288px 1087px #fff, 1572px 173px #fff, 1419px 41px #fff, 728px 1360px #fff, 1326px 1729px #fff, 1237px 942px #fff, 1362px 1321px #fff,
    1043px 12px #fff, 1535px 1069px #fff, 160px 49px #fff, 1659px 297px #fff, 833px 808px #fff, 1195px 524px #fff, 1758px 64px #fff, 1589px 14px #fff, 502px 388px #fff, 652px 563px #fff, 70px 603px #fff, 236px 360px #fff, 162px 452px #fff, 1377px 1826px #fff, 284px 521px #fff, 707px 1308px #fff, 1985px 1193px #fff, 1017px 18px #fff, 363px 1169px #fff, 1579px 503px #fff, 1538px 361px #fff, 256px 170px #fff, 1814px 41px #fff, 163px 991px #fff, 1251px 1391px #fff, 578px 1577px #fff, 1227px 223px #fff, 1781px 1507px #fff, 1111px 1906px #fff, 745px 1631px #fff, 1942px 469px #fff, 1396px 1159px #fff, 985px 1070px #fff, 1856px 490px #fff, 210px 1701px #fff, 1091px 655px #fff, 191px 368px #fff, 755px 1454px #fff, 908px 117px #fff, 1943px 1802px #fff, 1464px 1378px #fff, 1419px 1095px #fff, 143px 1638px #fff, 533px 888px #fff, 292px 261px #fff, 1445px 630px #fff, 1311px 1046px #fff;
}

#title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: "lato", sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
