@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  body {
    @apply text-[#e51376] tracking-wide;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-4xl sm:text-5xl md:text-6xl;
  }

  h2 {
    @apply text-3xl sm:text-4xl;
  }

  li {
    @apply cursor-pointer;
  }
}
